<template>
  <div class="letter-search page">
    <!-- 首字母搜索 -->
    <SearchBar
      placeholder=""
    ></SearchBar>
    <div class="letter-search-content">
      <div class="letter-search-content-left">
        <div class="letter-search-content-left-top">
          <div class="letter-search-content-left-top-search">
            <span :class="[ keyword && 'has-word' ]">{{ keyword ? keyword : '首拼搜索歌曲名'}}</span>
          </div>
          <div class="letter-search-content-left-top-btn" @mousedown.prevent="handleClickSelect">
            {{ selectList[wordsnum].label }}
            <img src="https://qncweb.ktvsky.com/20230921/vadd/1c40e4aaf2b8d357baf9b96498b1354d.png" alt="">
          </div>
          <input @blur="handleSelectBlur" ref="selectRef" type="text">
          <div class="letter-search-content-left-top-list" v-if="isShowSelect" @click.stop>
            <div class="letter-search-content-left-top-list-item" :class="[wordsnum === i && 'selected']" v-for="(s, i) in selectList" :key="i" @mousedown.prevent="handleSelect(i)">{{ s.label }}</div>
          </div>
        </div>
        <div class="letter-search-content-left-bot">
          <div class="letter-search-content-left-bot-item" :class="[ !canClickLetter && 'non-clickable']" v-for="(letter, index) in letterList" :key="index" @click="handleAddLetter(letter)">{{ letter }}</div>
          <div class="letter-search-content-left-bot-delete" @click.stop="handleDeleteLetter"></div>
        </div>
      </div>
      <div class="letter-search-content-right">
        <LoadMore
          class="song-list"
          v-if="songList.length"
          @load-more="handleSearchResult"
          :safeAreaHeight="safeAreaHeight"
        >
          <SongItem
            v-for="(songItem, index) in songList"
            :key="index"
            :songItem="songItem"
            :log-from="{
              song_list_source: 9,
            }"
            :ponitActionLog="{
              event_type: '10000~50000',
              event_name: 10008,
              event_data: {
                str1: '首页',
                str2: '歌名点歌',
                str3: '进入歌名点歌',
                str4: 'click',
              },
            }"
          ></SongItem>
        </LoadMore>
        <p v-else class="empty">
          暂无歌曲
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import { useStore } from 'vuex'
import SongItem from '@/components/song-item/index.vue'
import { letterSearch } from '@/service/letterSearch'
import { debounce } from 'lodash'
import { getSingsingList, getHotSongList } from '@/service/singing'
import { sendLog } from '@/directives/v-log/log'

export default {
  name: 'LetterSearchPage',
  components: {
    SongItem,
  },
  setup() {
    const store = useStore()
    const unionid = computed(() => store.state.userInfo.unionid)

    // 产品说不要 'V' 嗯~。。 
    const letterList = ref([
      'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'W', 'X', 'Y', 'Z'
    ])

    const selectList = ref([
      {
        value: 0,
        label: '全部',
      },
      {
        value: 1,
        label: '一字',
      },
      {
        value: 2,
        label: '二字',
      },
      {
        value: 3,
        label: '三字',
      },
      {
        value: 4,
        label: '四字',
      },
      {
        value: 5,
        label: '五字',
      },
      {
        value: 6,
        label: '六字',
      },
      {
        value: 7,
        label: '七字',
      },
      {
        value: 8,
        label: '八字',
      }
    ])

    let selectRef = ref(null)
    let keyword = ref('')
    // let isEmpty = ref(false)
    let songList = ref([])
    let pagination = 1
    let wordsnum = ref(0)
    let isRequest = false
    let isShowSelect = ref(false)
    let safeAreaHeight = ref('12vw')
    let canClickLetter = ref(true)

    onMounted(() => {
      getBrowserSize()
      fetchSingingList()
      window.addEventListener('resize', getBrowserSize)

      sendLog({
        event_type: '10000~50000',
        event_name: 10008,
        event_data: {
          str1: '首页',
          str2: '歌名点歌',
          str3: '进入歌名点歌',
          str4: 'click',
        },
      })
    })

    const fetchSingingList = async () => {
      if (unionid.value) {
        songList.value = await getSingsingList({
          unionid: unionid.value
        })
      } else {
        songList.value = await getHotSongList({
          p: 1,
          size: 50,
          unionid: ''
        })
      }
    }

    const getBrowserSize = () => {
      let clientWidth = document.body ? document.body.clientWidth : 0
      let clientHeight = document.body ? document.body.clientHeight : 0
      // 竖屏
      if (clientHeight > clientWidth) {
        safeAreaHeight.value = '55vh'
        console.log('竖屏', safeAreaHeight.value)
      } else {
        // 横屏
        safeAreaHeight.value = '12vw'
        console.log('横屏', safeAreaHeight.value)
      }
    }

    const handleClickSelect = () => {
      if (!isShowSelect.value) {
        selectRef.value.focus()
        isShowSelect.value = true
      } else {
        selectRef.value.blur()
      }
      sendLog({
        event_type: '10000~50000',
        event_name: 10034,
        event_data: {
          str1: '歌名点歌',
          str2: '输入区',
          str3: '点击全部',
          str4: 'click',
        },
      })
    }

    const handleSelectBlur = () => {
      isShowSelect.value = false
    }

    const handleSelect = (v) => {
      wordsnum.value = v
      sendLog({
        event_type: '10000~50000',
        event_name: 10034,
        event_data: {
          str1: '歌名点歌',
          str2: '输入区',
          str3: '点击全部',
          str4: 'click',
          str5: v,
          str6: 'click',
        },
      })
    }

    const handleAddLetter = debounce((l) => {
      keyword.value += l
    }, 300)

    const handleDeleteLetter = debounce(() => {
      canClickLetter.value = true
      let str = keyword.value.split('')
      str.pop()
      keyword.value = str.join('')
    }, 200)

    const handleOnSearch = (k) => {
      keyword.value = k
    }

    const handleSearchResult = async (type = 'search') => {
      if (isRequest || !keyword.value) {
        return
      }
      isRequest = true
      const res = await letterSearch(keyword.value, pagination, wordsnum.value)
      if (res.song && res.song.length) {
        songList.value = songList.value.concat(res.song)
        pagination ++
      } else {
        if (type === 'change') canClickLetter.value = false
      }
      isRequest = false
    }

    watch(keyword, (v) => {
      if (v) {
        songList.value = []
        pagination = 1
        handleSearchResult('change')
      }
    })

    watch(wordsnum, () => {
      if (keyword.value) {
        songList.value = []
        pagination = 1
        handleSearchResult('change')
      }
    })

    onUnmounted(() => {
      window.removeEventListener('resize', getBrowserSize)
    })

    return {
      selectRef,
      canClickLetter,
      letterList,
      selectList,
      wordsnum,
      songList,
      keyword,
      isShowSelect,
      safeAreaHeight,
      handleSelectBlur,
      handleClickSelect,
      handleSelect,
      handleOnSearch,
      handleAddLetter,
      handleDeleteLetter,
      handleSearchResult,
    }
  },
}
</script>

<style lang="stylus" scoped>
.letter-search
  width 100%
  height 100vh
  position relative
  color #000
  font-weight 400
  &-content
    width 100%
    height 100%
    display flex
    flex-direction row
    @media screen and (max-width 1200px) and (min-height 1200px)
      flex-direction column-reverse
    &-left
      width 772px
      height 706px
      margin-right 48px
      margin-top 40px
      &-top
        width 100%
        height 110px
        display flex
        background #000
        position relative
        &-search
          border 3px solid rgba(255, 255, 255, 0.2)
          border-radius 20px
          color rgba(255, 255, 255, 0.3)
          position relative
          padding-left 113px
          width 542px
          display flex
          align-items center
          span
            font-size 28px
            width 420px
          .has-word
            color rgba(255, 255, 255, 1) !important
          &::before
            content ""
            position absolute
            left 40px
            top 29px
            width 52px
            height 52px
            background url('https://qncweb.ktvsky.com/20230921/vadd/163fc1ffac72d358c4299d565e6ec00a.png') no-repeat
            background-position center
            background-size 100% 100%
        &-btn
          display flex
          width 210px
          height 110px
          font-size 28px
          color #fff
          opacity 0.8
          font-weight 400
          justify-content center
          align-items center
          border-radius 20px
          background #1E1F21
          margin-left 20px
          img
            width 32px
            height 32px
            margin-left 10px
        &-list
          position absolute
          right 0
          bottom -732px
          width 210px
          height 720px
          z-index 7
          border-radius 20px
          overflow auto
          background #2B2D30
          &::-webkit-scrollbar
            width 0
          &-item
            width 100%
            height 80px
            color rgba(255, 255, 255, 0.40)
            font-size 28px
            font-weight 400
            display flex
            align-items center
            justify-content center
          .selected
            background rgba(255, 255, 255, 0.10)
            color rgba(255, 255, 255, 0.80)
        input
          width 0
      &-bot
        width 100%
        height 546px
        margin-top 60px
        display grid
        grid-template-columns repeat(7, 100px)
        justify-content space-between
        position relative
        &-item
          border-radius 10px
          background #1E1F21
          width 100px
          height 122px
          color rgba(255, 255, 255, 0.80)
          font-size 28px
          display flex
          align-items center
          justify-content center
        &-delete
          position absolute
          bottom 16px
          right 0
          width 324px
          height 122px
          background url('https://qncweb.ktvsky.com/20230922/vadd/35f268610a691a47c3c7e3db06d6aaec.png') no-repeat
          background-position center
          background-size 100% 100%
        .non-clickable
          pointer-events none
          background rgba(30, 31, 33, 0.60)
          color rgba(255, 255, 255, 0.08)
      @media screen and (max-width 1200px) and (min-height 1200px)
        margin-right 0
        width 100%
        margin-top 0
        margin-bottom 200px
        &-top
          &-search
            width 806px
          &-list
            height 460px
            bottom -470px
            overflow-y auto
        &-bot
          height 414px !important
          grid-template-columns repeat(9, 106px)
          &-item
            font-size 36px
            width 106px
            height 126px
          &-delete
            width 224px
            height 126px
            background url('https://qncweb.ktvsky.com/20230922/vadd/b28bc57e41cb9cbee6d0512b9b461dfd.png') no-repeat
            background-position center
            background-size 100% 100%
    &-right
      width 940px
      height 910px
      margin-bottom 0
      .empty
        margin-top 20vh
        font-size 28px
        color rgba(255, 255, 255, 0.5)
        text-align center
      @media screen and (max-width 1200px) and (min-height 1200px)
        margin-bottom 50px
        width 100%
        height 1040px
        overflow hidden
</style>